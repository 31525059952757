// Required libraries
// ================================================================
import React, { useCallback, useRef, useEffect } from 'react';
import PT from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Grid from 'react-virtualized/dist/es/Grid';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';

// User Store import
// ================================================================
import {
  getResultCount,
  getResultByIndex,
  favoriteResult,
  fetchResult,
  ignoreResult
} from 'shared/Store/Results';

// Utility functions and constants
// ================================================================
import { getNumericalCssValue, cssClassBlink } from 'shared/util';
import { useBodyEvent } from 'shared/hooks';
import { SCROLLBAR_CSS_VAR, RESULT_SCROLL_EVENT } from 'shared/const';

// List Components to render
// ================================================================
import Button from 'trendolizer-ui/build/Button';
import LoadMoreButton from 'shared/Components/LoadMoreButton';
import Result from 'shared/Components/Result';
import ResultStatus from 'shared/Components/ResultStatus';
import InfiniteLoading from '../InfiniteLoading';

// Component declaration
// ================================================================
function ResultContainer(props) {
  const { sort, id, column, index } = props;

  // Get dispatch func and Result data from a store
  // ================================================================
  const { hash, favorite, ignore, ...data } = useSelector(
    getResultByIndex(column, index)
  );
  const dispatch = useDispatch();

  // Navigate to details results via hash
  // ================================================================
  const detailsHandler = useCallback(() => {
    window.location.hash = hash;
  }, [hash]);

  // Dispatch API request to refresh this result data
  // ================================================================
  const fetchHandler = useCallback(
    async (e) => {
      const $target = e.target.closest('.Result');
      $target.click();
      $target.classList.add('state-loading');
      const result = await dispatch(fetchResult({ hash, column }));
      $target.classList.remove('state-loading');
      await cssClassBlink($target, 'state-done', 250);
      return result;
    },
    [dispatch, column, hash]
  );

  // Dispatch API request to favorite this result
  // ================================================================
  const favoriteHandler = useCallback(
    () => dispatch(favoriteResult({ hash, column, favorite })),
    [dispatch, column, hash, favorite]
  );

  // Dispatch API request to ignore this result
  // ================================================================
  const ignoreHandler = useCallback(
    () => dispatch(ignoreResult({ hash, column, ignore })),
    [dispatch, column, hash, ignore]
  );

  return (
    <Result
      {...data}
      id={id}
      hash={hash}
      sort={sort}
      favorite={favorite}
      contextMenu
      tabIndex='0'
      onDoubleClick={detailsHandler}
    >
      <Button icon='new-window' text='Show Details' onClick={detailsHandler} />
      <Button icon='refresh' text='Refresh this link' onClick={fetchHandler} />
      <Button
        icon={favorite ? 'star' : 'star-outlined'}
        text={favorite ? 'Unfavorite' : 'favorite'}
        onClick={favoriteHandler}
      />
      <Button
        icon={ignore ? 'show' : 'hide'}
        text={ignore ? 'Unignore' : 'Ignore'}
        onClick={ignoreHandler}
      />
    </Result>
  );
}
// PropTypes Declaration
// ================================================================
ResultContainer.propTypes = {
  /** Unique ID for a Grid */
  id: PT.string.isRequired,
  /** Index of a result in a list */
  index: PT.number.isRequired,
  /** ID of a Column this result belongs to */
  column: PT.number.isRequired,
  /** Current sorting value of a Column */
  sort: PT.string.isRequired
};

// Component declaration
// ================================================================
export default function ResultList(props) {
  const {
    id,
    idle,
    loading,
    reloading,
    infinite,
    favorite,
    sort,
    error,
    width,
    fetchResults
  } = props;
  // Basic stuff
  // ================================================================
  const $grid = useRef();
  const prevStopIndex = useRef(0);
  const estimatedRowSize = 184;
  const SCROLLBAR_SIZE = getNumericalCssValue(SCROLLBAR_CSS_VAR);
  const adjustedWidth = width - SCROLLBAR_SIZE;

  // get Redux data
  // ================================================================
  const count = useSelector(getResultCount(id));
  const rowCount = count > 0 ? count + 1 : 0; // We add 1 to results count because we need to render load more or infiniteloading component

  // Function that calculates each result height
  // ================================================================
  const rowHeight = useCallback(
    ({ index }) => {
      const el = document.getElementById(`grid-${id}-${index}`);
      return el ? el.clientHeight : estimatedRowSize;
    },
    [id, estimatedRowSize]
  );

  // Fetch more results (with offset)
  // ================================================================
  const fetchMoreResults = useCallback(() => {
    if (idle) {
      fetchResults({ offset: count });
    }
  }, [count, idle, fetchResults]);

  // OnSectionRendered callback - logic here
  // ================================================================
  const onSectionRendered = useCallback(
    ({ rowOverscanStartIndex, rowOverscanStopIndex }) => {
      if (
        infinite &&
        rowOverscanStartIndex > 0 &&
        prevStopIndex.current === rowOverscanStopIndex &&
        rowOverscanStopIndex === count
      ) {
        fetchMoreResults(rowOverscanStopIndex);
      }

      // Re-render results - launch height recalculation
      // ================================================================
      if (
        count === rowOverscanStopIndex ||
        prevStopIndex.current !== rowOverscanStopIndex
      ) {
        $grid.current.recomputeGridSize({
          rowIndex: rowOverscanStartIndex,
          columnIndex: 0
        });
        prevStopIndex.current = rowOverscanStopIndex;
      }
    },
    [fetchMoreResults, count, infinite]
  );

  // Logic related to custom event allowing to scroll to index
  // to show specific Result
  // ================================================================
  const onScrollEvent = useCallback(
    (e) => {
      if ($grid.current && e.detail.id === id) {
        $grid.current.scrollToCell({
          columnIndex: 0,
          rowIndex: e.detail.index
        });
        setTimeout(() => {
          cssClassBlink(
            $grid.current._scrollingContainer.querySelector(
              `#grid-${e.detail.id}-${e.detail.index}`
            ),
            'state-done',
            4000
          );
        }, 150);
      }
    },
    [id]
  );

  // Show load more button if infinite loading
  // or just loading indicator otherwise
  // ================================================================
  let loadMoreComponent = (
    <LoadMoreButton loading={loading} onClick={fetchMoreResults} />
  );
  if (infinite) {
    loadMoreComponent = loading ? (
      <InfiniteLoading text='Loading more results...' />
    ) : null;
  }

  // Run "first" fetch on component mount
  // ================================================================
  useEffect(() => {
    fetchResults({ init: true });
  }, [fetchResults]);

  useBodyEvent(RESULT_SCROLL_EVENT, onScrollEvent);

  return (
    <AutoSizer disableWidth={!!width} defaultWidth={adjustedWidth}>
      {({ height }) => (
        <Grid
          ref={$grid}
          cellRenderer={({ rowIndex, key, style }) => {
            return (
              <div key={key} style={style} data-index={rowIndex}>
                {rowIndex < count ? (
                  <ResultContainer
                    index={rowIndex}
                    sort={sort}
                    column={id}
                    id={`grid-${id}-${rowIndex}`}
                  />
                ) : (
                  loadMoreComponent
                )}
              </div>
            );
          }}
          autoContainerWidth
          width={adjustedWidth}
          height={height}
          noContentRenderer={() => (
            <ResultStatus
              loading={reloading}
              error={error}
              favorite={favorite}
            />
          )}
          scrollToAlignment='start'
          columnWidth={adjustedWidth - SCROLLBAR_SIZE}
          onSectionRendered={onSectionRendered}
          estimatedRowSize={estimatedRowSize}
          columnCount={1}
          tabIndex={null}
          rowCount={error || reloading ? 0 : rowCount}
          rowHeight={rowHeight}
          overscanRowCount={3}
        />
      )}
    </AutoSizer>
  );
}

// PropTypes Declaration
// ================================================================
ResultList.propTypes = {
  /** ID of Column that this results belongs to */
  id: PT.number.isRequired,
  /** Width of a Column needed by [react-virtualized] */
  width: PT.number.isRequired,
  /** Indicates if loading additional results is in progress */
  loading: PT.bool,
  /** Indicates if reloading is in progress */
  reloading: PT.bool,
  /** Indicates if state is idle */
  idle: PT.bool,
  /** Whatever column has infiniteloading enabled */
  infinite: PT.bool,
  /** Current column sorting parameter */
  sort: PT.string.isRequired,
  /** Network error during results fetching */
  error: PT.string,
  /** Dispatch statefull results fetch */
  fetchResults: PT.func,
  /** Whatever given column is dedicated to show "favorite" results */
  favorite: PT.bool
};
